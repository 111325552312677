import TestSharedComp from '@apps/shared/src/components/TestSharedComp';
import { useState } from 'react';
import { nameNormalize } from '@udok/lib/internal/util';
import Loadable from '@udok/lib/components/Loadable/Loadable';

const Split = Loadable({
  loader: () => import('./Split'),
  loading: (p: any) => <div>{JSON.stringify(p)}</div>,
});

const Main = () => {
  const [clickCount, setClickCount] = useState(0);

  return (
    <div onClick={() => setClickCount(c => c + 1)}>
      <h3>App 1</h3>
      {clickCount > 1 ? <Split /> : null}
      {nameNormalize('some RANDOM name    heree    .')}
      <div>Click count: {clickCount}</div>
    </div>
  );
};

export default Main;
