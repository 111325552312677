import React from 'react';
import axios from 'axios';

export type LoadableProps = {
  loading: any;
  loader: any;
};

class ErrorBoundary extends React.Component<{
  fallback?: React.Component<any>;
}> {
  state = { hasError: false, message: '', status: '' };
  static getDerivedStateFromError(error: any) {
    console.log('error', error, { ...error }, error?.type);
    //TypeError: error loading dynamically imported module
    return { hasError: true, message: error?.message, status: error?.status };
  }

  render() {
    function addExtraProps(Component: any, extraProps: any) {
      return <Component.type {...Component.props} {...extraProps} />;
    }

    if (this.state.hasError) {
      return addExtraProps(this.props.fallback, {
        errorMessage: this.state.message,
        errorStatus: this.state.status,
      });
    }
    return this.props.children;
  }
}

function retry(fn: () => Promise<any>, Fallback: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const p = fn();
    p.then(resolve).catch(async e => {
      const url = fn.toString().match(/import\(['"](.*?)['"]\)/)?.[1];
      let errorType = 'error';
      if (url) {
        const basePath =
          import.meta.url ??
          new URL(
            './Split.js',
            new URL(window.location as any as string).origin + '/assets/'
          ).href;
        errorType = await axios
          .head(new URL(url, basePath).href, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              Pragma: 'no-cache',
              Expires: 0,
            },
          })
          .then(() => 'error')
          .catch(e => {
            console.log('error axios', e);
            if (e?.response?.status !== 200) {
              return 'missing';
            }
            return 'error';
          });
      }
      console.log('-->', e, errorType);
      resolve({
        default: (p: any) => (
          <Fallback {...p} error={{ type: errorType, message: e?.message }} />
        ),
      });
    });
    console.log('!!!', p, fn.toString());
  });
}

export function Loadable({ loader, loading: Loading }: LoadableProps) {
  const Loader = React.lazy(() => retry(loader, Loading));
  return (p: any) => (
    <ErrorBoundary fallback={Loading}>
      <React.Suspense fallback={<Loading /> ?? <>...</>}>
        <Loader {...p} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default Loadable;
